import React, { Component, createRef } from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Slide,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Loading from "../../../Components/Loading";
import { Alert } from "@material-ui/lab";
import { ArrowBack, FilterList, CloudDownload } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Save } from "@material-ui/icons";
import Style from "./style.module.scss";
import {
  getWbs,
  clearReport,
  getDPRWbs,
  getMaterialsWbs,
  getLaboursWbs,
  getcontractorRaWbs,
  getStoreWbs,
  getSupplierWbs,
  getDPRReportBySuperProject,
} from "../../../Redux/superProject/wbsRedux/wbsAction";
import ActivityReport from "./ActivityReport";
import Filter from "./FilterBox";
import MisHeader from "./MisHeader";

import DPRReport from "./DPR Report/DPRReport";
import FilterDPRReport from "./DPR Report/FilterDPRReport";
import MaterialsReport from "./Materials Report/MaterialsReport";
import FilterMaterialsReport from "./Materials Report/FilterMaterialsReport";
import LaboursReport from "./Labours Report/LaboursReport";
import FilterLaboursReport from "./Labours Report/FilterLaboursReport";

import ContractorRaReport from "./Contractor RA Report/ContractorRaReport";
import FilterContractorRa from "./Contractor RA Report/FilterContractorRa";
import SuppilerReport from "./Suppiler Report/SupplierReport";
import FilterSuppilerReport from "./Suppiler Report/FilterSupplierReport";

import StoreReport from "./Store Report/StoreReport";
import FilterStoreReport from "./Store Report/FilterStoreReport";
import axios from "axios";

import downloadPDF, {
  export_To_Excel,
  exportToExcel,
} from "../../../Components/pdf";
class SuperProjectWbs extends Component {
  constructor(props) {
    // console.log(iterList);
    super(props);
    this.state = {
      modalOpen: false,
      loading: true,
      activeReport: 0,
      showFilter: false,
      showScreen: false,
      startDate: "",
      endDate: "",
      DprReportFormat: "",
      ReportFormat: "",
      openDownloadStarted: false,
      actprojectIdWbs: [],
      visibleReports: [],
      itemsToShow: 10,
      hasMore: true,
      buttonLoading: false,
      // show:true
    };
    this.observerRef = createRef();
  }

  TransitionLeft = props => {
    return <Slide {...props} direction="left" />;
  };
  componentDidMount() {
    try {
    } catch (error) {}
  }
  Reports = [
    "DPR Report",
    "Material wise supplier report",
    "Supplier wise Material  report",
    "Labour wise Supplier report",
    "Supplier wise Labour report",
    "Contractor wise items report",
    "Item wise Contractors report",
    "Crusher Sale Party Name wise Material report",
    "Crusher Sale Material wise Party Name report",
    "Store report",
  ];

  clearReportAll = () => {
    this.props.clearReport();
    this.setState({
      startDate: "",
      endDate: "",
      DprReportFormat: "",
      ReportFormat: "",
    });
  };

  handleShowReport = i => {
    console.log(i);
    this.setState({
      activeReport: i ? i : 0,
      showScreen: !this.state.showScreen,
    });
  };
  handleFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  handleInputChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  getReport = activityName => {
    this.props.getWbs(activityName, this.state.startDate, this.state.endDate);
    this.setState({
      startDate: "",
      endDate: "",
    });
    this.handleFilter();
  };
  convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  getDPRReport = activityName => {
    // this.props.getDPRWbs(this.state.startDate, this.state.endDate);
    this.props.getDPRReportBySuperProject(
      this.state.startDate,
      this.state.endDate
    );
    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      DprReportFormat: activityName,
    });
    this.handleFilter();
  };

  // componentDidMount() {
  //   this.loadInitialData();
  // }

  // loadInitialData = () => {
  //   const { newWbs } = this.props;
  //   const { itemsToShow } = this.state;

  //   this.setState({
  //     visibleReports: newWbs.slice(0, itemsToShow),
  //     hasMore: newWbs.length > itemsToShow,
  //   });
  // };

  // loadMoreReports = () => {
  //   const { newWbs } = this.props;
  //   const { visibleReports, itemsToShow } = this.state;
  //   this.setState({ buttonLoading: true });
  //   // const nextChunk = newWbs.slice(
  //   //   visibleReports.length,
  //   //   visibleReports.length + itemsToShow
  //   // );
  //   // this.setState((prevState) => ({
  //   //   visibleReports: [...prevState.visibleReports, ...nextChunk],
  //   //   hasMore: visibleReports.length + nextChunk.length < newWbs.length,
  //   // }));

  //   setTimeout(() => {
  //     const nextChunk = newWbs.slice(
  //       visibleReports.length,
  //       visibleReports.length + itemsToShow
  //     );

  //     this.setState((prevState) => ({
  //       visibleReports: [...prevState.visibleReports, ...nextChunk],
  //       hasMore: visibleReports.length + nextChunk.length < newWbs.length,
  //       buttonLoading: false,
  //     }));
  //   }, 500);
  // };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.newWbs !== this.props.newWbs) {
  //     console.log("newWbs updated");
  //     this.setState({ visibleReports: [], hasMore: true }, this.loadMoreReports);
  //   }
  // }

  // loadInitialData = () => {
  //   const { newWbs } = this.props;
  //   const { itemsToShow } = this.state;
  //   this.setState({
  //     visibleReports: newWbs.slice(0, itemsToShow),
  //     hasMore: newWbs.length > itemsToShow,
  //   });
  // };

  // loadMoreReports = () => {
  //   const { newWbs } = this.props;
  //   const { visibleReports, itemsToShow } = this.state;
  //   const nextChunk = newWbs.slice(
  //     visibleReports.length,
  //     visibleReports.length + itemsToShow
  //   );
  //   this.setState((prevState) => ({
  //     visibleReports: [...prevState.visibleReports, ...nextChunk],
  //     hasMore: visibleReports.length + nextChunk.length < newWbs.length,
  //   }));
  // };

  getMaterialsReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 1) {
      ReportFormat = "Material Wise";
    }
    if (this.state.activeReport == 2) {
      ReportFormat = "Suppiler Wise";
    }
    this.props.getMaterialsWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getLaboursReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 3) {
      ReportFormat = "Labours Wise";
    }
    if (this.state.activeReport == 4) {
      ReportFormat = "Suppiler Wise";
    }
    this.props.getLaboursWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getcontractorRaReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 5) {
      ReportFormat = "Contractor Wise";
    }
    if (this.state.activeReport == 6) {
      ReportFormat = "Item Wise";
    }

    this.props.getcontractorRaWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };

  getsuppilerReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 7) {
      ReportFormat = "Supplier Wise";
    }
    if (this.state.activeReport == 8) {
      ReportFormat = "Material Wise";
    }

    console.log("activeReport", ReportFormat);
    this.props.getSupplierWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };

  getStoreReport = () => {
    this.props.getStoreWbs(this.state.startDate, this.state.endDate);

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
    });
    this.handleFilter();
  };
  componentWillUnmount() {
    this.props.clearReport();
  }

  shownotifications() {
    return (
      <>
        {this.state.openDownloadStarted && (
          <Snackbar
            open={this.state.openDownloadStarted}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={this.TransitionLeft}
            onClose={() =>
              this.setState({
                openDownloadStarted: false,
              })
            }
          >
            <Alert
              severity="success"
              onClose={() =>
                this.setState({
                  openDownloadStarted: false,
                })
              }
              style={{ borderTop: "5px solid green" }}
            >
              <div style={{ color: "green" }}>
                <strong>Download Started</strong>
              </div>
              Check Your Downloads Location!
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }

  getStepContent() {
    switch (this.state.activeReport) {
      case 0:
        return (
          <div>
            {this.getReportContent()}
            <FilterDPRReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getDPRReport={this.getDPRReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 1:
        return (
          <div>
            {this.getReportContent()}
            <FilterMaterialsReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getMaterialsReport={this.getMaterialsReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 2:
        return (
          <div>
            {this.getReportContent()}
            <FilterMaterialsReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getMaterialsReport={this.getMaterialsReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 3:
        return (
          <div>
            {this.getReportContent()}
            <FilterLaboursReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getLaboursReport={this.getLaboursReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 4:
        return (
          <div>
            {this.getReportContent()}
            <FilterLaboursReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getLaboursReport={this.getLaboursReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 5:
        return (
          <div>
            {this.getReportContent()}
            <FilterContractorRa
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getcontractorRaReport={this.getcontractorRaReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 6:
        return (
          <div>
            {this.getReportContent()}
            <FilterContractorRa
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getcontractorRaReport={this.getcontractorRaReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );

      case 7:
        return (
          <div>
            {this.getReportContent()}
            <FilterSuppilerReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getsuppilerReport={this.getsuppilerReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 8:
        return (
          <div>
            {this.getReportContent()}
            <FilterSuppilerReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getsuppilerReport={this.getsuppilerReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
      case 9:
        return (
          <div>
            {this.getReportContent()}
            <FilterStoreReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getStoreReport={this.getStoreReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );

      default:
        return "Unknown Report";
    }
  }

  getReportContent() {
    switch (this.state.activeReport) {
      case 0:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    exportToExcel(
                      this.props.newWbs,
                      "MIS DPR",
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {/* {this.props.loading ? (
              <Loading loading={true} />
            ) : this.props.newWbs.length > 0 ? ( */}
            <div className="container" id="download-section">
              {/* {this.state.visibleReports.map((ele, index) => (
                    <DPRReport
                        showFilter={this.state.showFilter}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        loading={this.props.loading}
                        newWbs={ele.data}
                        projectName={ele.projectName}
                        DprReportFormat={this.state.DprReportFormat}
                        handleInputChange={this.handleInputChange}
                      />
                ))} */}
              <DPRReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                projects={this.props.newWbs}
                DprReportFormat={this.state.DprReportFormat}
                handleInputChange={this.handleInputChange}
              />
              {/* {this.state.hasMore && (
                  <button
                    onClick={this.loadMoreReports}
                    disabled={this.state.buttonLoading}
                    style={{
                      display: "block",
                      margin: "20px auto",
                      padding: "10px 20px",
                      cursor: this.state.buttonLoading ? "not-allowed" : "pointer",
                      backgroundColor: this.state.buttonLoading ? "#ccc" : "#007bff",
                      color: this.state.buttonLoading ? "#666" : "#fff",
                    }}
                  >
                    {this.state.buttonLoading ? "Loading..." : "Load More"}
                  </button>
                )}

                {!this.state.hasMore && (
                  <p style={{ textAlign: "center", marginTop: "20px" }}>
                    No more data to load.
                  </p>
                )} */}
            </div>
            {/* ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )} */}
          </div>
        );
      case 1:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("MIS Material", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <MaterialsReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 2:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <MaterialsReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 3:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("MIS Labours", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <LaboursReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 4:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    export_To_Excel(
                      this.props.newWbs,
                      "Suppiler Wise Labor",
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <LaboursReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 5:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("Contractor Ra Report", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <ContractorRaReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 6:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("Contractor Ra Report", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <ContractorRaReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                      ReportFormat={this.state.ReportFormat}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 7:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("Store Report", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      "Crusher Sale Supplier",
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
                {this.props.csvData.length > 0 && (
                  <Button
                    className="m-2"
                    color="secondary"
                    startIcon={<CloudDownload />}
                  >
                    <CSVLink
                      style={{ color: "white" }}
                      data={this.props.csvData}
                      headers={[
                        { label: "Supplier Name", key: "name" },
                        { label: "Project Name", key: "projectName" },
                        { label: "Serial No", key: "serial" },
                        { label: "Date", key: "date" },
                        { label: "Material", key: "partyMaterial" },
                        { label: "DC", key: "dc" },
                        { label: "Location", key: "location" },
                        { label: "Quanity", key: "quantity" },
                        { label: "unit", key: "unit" },
                      ]}
                    >
                      Download CSV
                    </CSVLink>
                  </Button>
                )}
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <SuppilerReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      ReportFormat={this.state.ReportFormat}
                      handleInputChange={this.handleInputChange}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 8:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("Store Report", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      "Crusher Sale Material",
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>

                {this.props.csvData.length > 0 && (
                  <Button
                    className="m-2"
                    color="secondary"
                    startIcon={<CloudDownload />}
                  >
                    <CSVLink
                      style={{ color: "white" }}
                      data={this.props.csvData}
                      headers={[
                        { label: "Supplier Name", key: "name" },
                        { label: "Project Name", key: "projectName" },
                        { label: "Serial No", key: "serial" },
                        { label: "Date", key: "date" },
                        { label: "Material", key: "partyMaterial" },
                        { label: "DC", key: "dc" },
                        { label: "Location", key: "location" },
                        { label: "Quanity", key: "quantity" },
                        { label: "unit", key: "unit" },
                      ]}
                    >
                      Download CSV
                    </CSVLink>
                  </Button>
                )}
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <SuppilerReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      ReportFormat={this.state.ReportFormat}
                      handleInputChange={this.handleInputChange}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 9:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleShowReport(0);
                    this.clearReportAll();
                  }}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    // downloadPDF("Store Report", "p");
                    export_To_Excel(
                      this.props.newWbs,
                      this.state.ReportFormat,
                      global.config.secureStorage.getItem("user_fullname"),
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <div className="container" id="download-section">
                <MisHeader
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                {this.props.newWbs.map((ele, index) => {
                  return (
                    <StoreReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={ele.data}
                      projectName={ele.projectName}
                      handleInputChange={this.handleInputChange}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      default:
        return "Unknown Report";
    }
  }

  render() {
    return this.state.showScreen ? (
      <>{this.getStepContent()}</>
    ) : (
      !this.state.showScreen && (
        <div className="container">
          <List
            className={Style.container___info}
            style={{ marginTop: "30px" }}
          >
            {this.Reports.map((label, i) => (
              <ListItem
                className={Style.ListItem}
                onClick={() => this.handleShowReport(i)}
              >
                <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
                  {label}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  console.log(state.subProjectWbsReducer.csvData);
  return {
    loading: state.subProjectWbsReducer.loading,
    newWbs: state.subProjectWbsReducer.newWbs,
    csvData: state.subProjectWbsReducer.csvData,
  };
};

export default connect(mapStateToProps, {
  getWbs,
  clearReport,
  getDPRWbs,
  getMaterialsWbs,
  getLaboursWbs,
  getcontractorRaWbs,
  getStoreWbs,
  getSupplierWbs,
  getDPRReportBySuperProject,
})(SuperProjectWbs);
