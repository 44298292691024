import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";

// export const exportToExcel = (data, filename, generatedBy, startDate, endDate) => {
//   const wb = XLSX.utils.book_new();
//   const ws_data = [];
//   const headerMapping = {
//     equipment: "Equipment",
//     unit: "Unit",
//     date: "Date",
//     equipmentMaterial: "Material",
//     start: "Start Time",
//     end: "End Time",
//     remark: "Remark",
//     totalHours: "Total",
//     trip: "Trip",
//     diesel: "Diesel",
//     number: "Quantity",
//   };

//   const headerOrder = [
//     "equipment",
//     "unit",
//     "date",
//     "equipmentMaterial",
//     "start",
//     "end",
//     "remark",
//     "totalHours",
//     "trip",
//     "diesel",
//     "number",
//   ];

//   data.forEach((item) => {
//     // Add project name as a header
//     ws_data.push([`Project Name: ${item.projectName}`]);

//     if (item.data.length > 0) {
//       // Map and order headers
//       const headers = headerOrder.map((key) => headerMapping[key]);
//       ws_data.push(headers);

//       // Map and order rows based on the headerOrder, excluding "countUnit"
//       item.data.forEach((row) => {
//         const rowData = headerOrder.map((key) => row[key] ?? "");
//         ws_data.push(rowData);
//       });
//     } else {
//       ws_data.push(["No data available"]);
//     }

//     ws_data.push([], []);
//   });

//   const ws = XLSX.utils.aoa_to_sheet(ws_data);

//   const columnWidths = [
//     { wch: 20 }, // Equipment
//     { wch: 10 }, // Unit
//     { wch: 15 }, // Date
//     { wch: 20 }, // Material
//     { wch: 15 }, // Start Time
//     { wch: 15 }, // End Time
//     { wch: 30 }, // Remark
//     { wch: 10 }, // Total
//     { wch: 10 }, // Trip
//     { wch: 10 }, // Diesel
//     { wch: 10 }, // Quantity
//   ];

//   ws["!cols"] = columnWidths;

//   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

//   XLSX.writeFile(wb, `${filename}.xlsx`);
// };

export const exportToExcel = (
  data,
  filename,
  generatedBy,
  startDate,
  endDate
) => {
  const wb = XLSX.utils.book_new();
  const ws_data = [];

  ws_data.push(["Enbuild"]);
  ws_data.push([]);
  const generatedOn = new Date().toLocaleDateString();
  ws_data.push([
    `Generated By: \n${generatedBy || "N/A"}`,
    `Generated On: \n${generatedOn}`,
    `Start Date: \n${startDate || "N/A"}`,
    `End Date: \n${endDate || "N/A"}`,
  ]);

  ws_data.push([]);

  const headerMapping = {
    equipment: "Equipment",
    unit: "Unit",
    date: "Date",
    equipmentMaterial: "Material",
    start: "Start Time",
    end: "End Time",
    remark: "Remark",
    totalHours: "Total",
    trip: "Trip",
    diesel: "Diesel",
    number: "Quantity",
  };

  const headerOrder = [
    "equipment",
    "unit",
    "date",
    "equipmentMaterial",
    "start",
    "end",
    "remark",
    "totalHours",
    "trip",
    "diesel",
    "number",
  ];

  data.forEach(item => {
    ws_data.push([`Project Name: ${item.projectName}`]);

    if (item.data.length > 0) {
      const headers = headerOrder.map(key => headerMapping[key]);
      ws_data.push(headers);

      item.data.forEach(row => {
        const rowData = headerOrder.map(key => row[key] ?? "");
        ws_data.push(rowData);
      });
    } else {
      ws_data.push(["No data available"]);
    }

    ws_data.push([], []);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);

  const columnWidths = [
    { wch: 20 }, // Equipment
    { wch: 10 }, // Unit
    { wch: 15 }, // Date
    { wch: 20 }, // Material
    { wch: 15 }, // Start Time
    { wch: 15 }, // End Time
    { wch: 30 }, // Remark
    { wch: 10 }, // Total
    { wch: 10 }, // Trip
    { wch: 10 }, // Diesel
    { wch: 10 }, // Quantity
  ];
  const metadataColumnWidths = [
    { wch: 25 }, // Generated By
    { wch: 25 }, // Generated On
    { wch: 25 }, // Start Date
    { wch: 25 }, // End Date
  ];

  ws["!cols"] = [...metadataColumnWidths, ...columnWidths];

  // Add styles
  const style = {
    fill: {
      fgColor: { rgb: "4CAF50" },
    },
    font: {
      color: { rgb: "FFFFFF" },
      sz: 22,
    },
  };

  ws["A1"].s = style;

  // const cellEnbuild = ws["A1"];
  //   cellEnbuild.s = {
  //     font: { bold: true, color: { rgb: "FFFFFF" }, sz: 22 },
  //     fill: { bgColor: { rgb: "4CAF50" } },
  //     alignment: { horizontal: "center", vertical: "center" }
  //   };
  const headerRange = {
    s: { r: 0, c: 0 },
    e: { r: 0, c: headerOrder.length - 1 },
  };
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(headerRange);
  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][0] = { hpt: 30 };

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, `${filename}.xlsx`);
};

// export const export_To_Excel = (data, ReportFormat, generatedBy, startDate, endDate) => {
//   console.log("data>?>?>??>?>", data);

//   const flattenedData = [];

//   flattenedData.push(["Enbuild"]);
//   flattenedData.push([]);
//   const generatedOn = new Date().toLocaleDateString();
//   flattenedData.push([
//     `Generated By: \n${generatedBy || "N/A"}`,
//     `Generated On: \n${generatedOn}`,
//     `Start Date: \n${startDate || "N/A"}`,
//     `End Date: \n${endDate || "N/A"}`,
//   ]);

//   flattenedData.push([]);

//   data.forEach((elem) => {
//     let totalQuantity = 0;

//     elem.data.forEach((materialItem) => {
//       materialItem.elm.forEach((row, index) => {
//         const quantity = parseFloat(row.number) || 0;
//         totalQuantity += quantity;

//         flattenedData.push({
//           "Serial No": index + 1,
//           "Date": row.date,
//           [ReportFormat === "Material Wise" ? "Supplier" : "Material"]: row.supplier,
//           "Quantity": quantity.toFixed(2),
//           "Unit": row.unit,
//           "Material Name": materialItem.mat || "",
//         });
//       });
//       flattenedData.push({
//         "Serial No": "",
//         "Date": "",
//         [ReportFormat === "Material Wise" ? "Supplier" : "Material"]: "",
//         "Quantity": "",
//         "Unit": "Total",
//         "Material Name": totalQuantity.toFixed(2),
//       });
//     });

//   });

//   const ws = XLSX.utils.json_to_sheet(flattenedData);
//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "MaterialsReport");
//   XLSX.writeFile(wb, "MaterialsReport.xlsx");
// };

export const export_To_Excel = (
  data,
  ReportFormat,
  generatedBy,
  startDate,
  endDate
) => {
  console.log("data>?>?>??>?>", data);
  console.log("ReportFormat received:", ReportFormat);
  console.log("generatedBy", generatedBy);

  const flattenedData = [];
  flattenedData.push(["Enbuild"]);
  flattenedData.push([]);
  const generatedOn = new Date().toLocaleDateString();
  flattenedData.push([
    `Generated By: \n${generatedBy || "N/A"}`,
    `Generated On: \n${generatedOn}`,
    `Start Date: \n${startDate || "N/A"}`,
    `End Date: \n${endDate || "N/A"}`,
  ]);
  flattenedData.push([]);

  data.forEach(elem => {
    flattenedData.push([`Project Name: ${elem.projectName || ""}`]);
    flattenedData.push([]);

    elem.data.forEach(materialItem => {
      if (
        ReportFormat == "Material Wise" ||
        ReportFormat == "Crusher Sale Material"
      ) {
        flattenedData.push([`Material Name: ${materialItem.mat || ""}`]);
      } else if (
        ReportFormat == "Suppiler Wise" ||
        ReportFormat == "Suppiler Wise Labor" ||
        ReportFormat == "Crusher Sale Supplier"
      ) {
        flattenedData.push([`Supplier Name: ${materialItem.mat || ""}`]);
      } else if (ReportFormat == "Labours Wise") {
        flattenedData.push([`Labour Name: ${materialItem.mat || ""}`]);
      } else if (ReportFormat == "Contractor Wise") {
        flattenedData.push([`Contractor Name: ${materialItem.mat || ""}`]);
      } else if (ReportFormat == "Item Wise") {
        flattenedData.push([`Item Name: ${materialItem.mat || ""}`]);
      }

      if (
        ReportFormat == "Crusher Sale Supplier" ||
        ReportFormat == "Crusher Sale Material"
      ) {
        flattenedData.push([
          "Serial No",
          "Date",
          ReportFormat == "Crusher Sale Supplier" ? "Material" : "Supplier",
          "DC",
          "Location",
          "Quantity",
          "Unit",
        ]);
      } else {
        flattenedData.push([
          "Serial No",
          "Date",
          ReportFormat == "Material Wise" || ReportFormat == "Labours Wise"
            ? "Supplier"
            : ReportFormat == "Suppiler Wise Labor"
            ? "Labor"
            : ReportFormat == "Contractor Wise"
            ? "Item"
            : ReportFormat == "Item Wise"
            ? "Contractor"
            : "Material",
          "Quantity",
          "Unit",
        ]);
      }

      let totalQuantity = 0;
      materialItem.elm.forEach((row, index) => {
        if (
          ReportFormat == "Crusher Sale Supplier" ||
          ReportFormat == "Crusher Sale Material"
        ) {
          const quantity = parseFloat(row.quantity) || 0;
          totalQuantity += quantity;
          flattenedData.push([
            index + 1,
            row.date,
            ReportFormat == "Crusher Sale Supplier"
              ? row.partyMaterial
              : row.name,
            row.dc,
            row.location,
            quantity.toFixed(2),
            row.unit,
          ]);
        } else {
          const quantity = parseFloat(row.number) || 0;
          totalQuantity += quantity;
          flattenedData.push([
            index + 1,
            row.date,
            ReportFormat == "Contractor Wise"
              ? row.item
              : ReportFormat == "Labours Wise"
              ? row.supplier
              : ReportFormat == "Suppiler Wise Labor"
              ? row.labor
              : ReportFormat == "Item Wise"
              ? row.contractor
              : row.material,
            quantity.toFixed(2),
            row.unit,
          ]);
        }
      });

      flattenedData.push(["", "", "", "Total", totalQuantity.toFixed(2)]);
      flattenedData.push([]);
    });

    flattenedData.push([]);
  });

  const ws = XLSX.utils.aoa_to_sheet(flattenedData);

  ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];

  const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "MaterialsReport");
  // XLSX.writeFile(wb, `MaterialsReport.xlsx`);

  const sheetName =
    {
      "Material Wise": "materialWiseSupplier",
      "Crusher Sale Material": "crusherSaleMaterial",
      "Suppiler Wise": "supplierMaterial",
      "Suppiler Wise Labor": "supplierWiseLabour",
      "Crusher Sale Supplier": "CrusherSaleSupplier",
      "Labours Wise": "labourSupplier",
      "Contractor Wise": "contractorSupplier",
      "Item Wise": "itemSupplier",
    }[ReportFormat] || "MaterialsReport";

  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  XLSX.writeFile(wb, `${sheetName}.xlsx`);
};

const downloadPDF = (filename, ori, data) => {
  console.log("full data", data);
  let defOri = ori == "p" ? "portrait" : ori == "l" && "landscape";
  let yourDate = new Date();
  yourDate = yourDate.toISOString().split("T")[0];
  const source = document.getElementById("download-section");
  var w = source.offsetWidth;
  var h = source.offsetHeight;
  var opt = {
    margin: 2,
    filename: filename + "-" + yourDate + ".pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, dpi: 400 },
    pagebreak: { avoid: ".tableInside tr" },
    // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    jsPDF: { unit: "mm", format: "a4", orientation: defOri },
  };
  html2pdf().from(source).set(opt).save();
  // var w = source.offsetWidth;
  // var h = source.offsetHeight;
};

export default downloadPDF;
